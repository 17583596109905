// dropdown
.dropdown-toggle {
    &:after {
        display: none;
    }
}

.loginAdjust {
    padding: 50px 300px !important;
    @media (max-width: 640px) {
        padding: 3rem !important;
    }
}

.nav-width-mine {
    width: 24%;
    @media (max-width: 640px) {
        width: 100%;
    }
}

.formEdit {
    padding: 9px;
    border-radius: 7px;
    background-color: #F5F5F5;
}

.formEditTop {
    padding: 9px;
    border-radius: 7px;
    background-color: #fff;
}

.input-icon {
    position: absolute;
    top: 22px;
    right: 20px;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
}

.input-icon-settings {
    position: absolute;
    top: 20px;
    right: 20px;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
}

.input-icon-settings2 {
    position: absolute;
    top: 20px;
    right: -30px;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
    font-size: 18px;
}

.input-icon-checkout {
    position: absolute;
    top: 20px;
    right: 9px;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer;
}

.text-light-grey {
    color: #969BA0;
}

.text-medium-grey {
    color: #6C6A78;
}

.topUseBtn {
    border-radius: 8px !important;
    background: #231F20 !important;
    color: #fff;
    padding: 12px 30px;
    &:hover {
        color: #fff;
    }
    &:focus {
        box-shadow: none;
    }
}

.topUseBtnTop {
    border-radius: 8px !important;
    background: #231F20 !important;
    color: #fff;
    padding: 12px 30px;
    &:hover {
        color: #fff;
    }
    &:focus {
        box-shadow: none;
    }
    @media (max-width: 640px) {
        padding: 12px 20px;
    }
}

.topUseBtnTopInitiate {
    border-radius: 8px !important;
    background: #FFF2B2 !important;
    color: #3E4954;
    padding: 12px 30px;
    &:hover {
        color: #3E4954;
    }
    &:focus {
        box-shadow: none;
    }
    @media (max-width: 640px) {
        padding: 12px 20px;
    }
}

.topUseBtnTopFlag {
    border-radius: 8px !important;
    background: #fff !important;
    color: #3E4954;
    padding: 12px 10px;
    border: 1px solid #E9EFFF;
    &:hover {
        color: #3E4954;
    }
    &:focus {
        box-shadow: none;
    }
    @media (max-width: 640px) {
        padding: 12px 20px;
    }
}

.topUseBtnTopCard {
    border-radius: 8px !important;
    background: #6F4183 !important;
    color: #fff;
    padding: 12px 30px;
    &:hover {
        color: #fff;
    }
    &:focus {
        box-shadow: none;
    }
    @media (max-width: 640px) {
        padding: 12px 20px;
    }
}

.makeWithdrawBtn {
    border-radius: 4px !important;
    background: #fff !important;
    color: #231F20;
    font-size: 12px;
    padding: 7px 22px;
    &:hover {
        color: #231F20;
    }
    &:focus {
        box-shadow: none;
    }
}

.makeWithdrawBtnSecond {
    border-radius: 4px !important;
    background: #fff !important;
    color: #231F20;
    font-size: 12px;
    padding: 7px 15px;
    &:hover {
        color: #231F20;
    }
    &:focus {
        box-shadow: none;
    }
}

.setupBtn {
    border-radius: 8px !important;
    background: #6F4183 !important;
    color: #fff;
    padding: 10px 45px;
    &:hover {
        color: #fff;
    }
    &:focus {
        box-shadow: none;
    }
}

.setupBtn2 {
    border-radius: 8px !important;
    background: #000 !important;
    color: #fff;
    padding: 10px 45px;
    &:hover {
        color: #fff;
    }
    &:focus {
        box-shadow: none;
    }
}

.appLinkBtn {
    border-radius: 8px !important;
    background: #6F4183 !important;
    color: #fff;
    padding: 10px 15px;
    float: right;
    &:hover {
        color: #fff;
    }
    &:focus {
        box-shadow: none;
    }
    @media (max-width: 640px) {
        float: left;
        margin-top: 25px;
    }
}

.inviteBtn {
    border-radius: 4px !important;
    background: #6F4183 !important;
    color: #fff;
    // padding: 6px 17px;
    &:hover {
        color: #fff;
    }
    &:focus {
        box-shadow: none;
    }
}

.cancelInviteBtn2 {
    border-radius: 4px !important;
    background: #fff !important;
    color: #6F4183;
    // padding: 6px 17px;
    border: 1px solid #6F4183;
    &:hover {
        background: #6F4183 !important;
        color: #fff;
        border: 1px solid #6F4183;
    }
    &:focus {
        box-shadow: none;
    }
}

.cancelStaticBtn {
    border-radius: 4px !important;
    background: #F3F3F3 !important;
    color: #000;
    padding: 6px 17px;
    &:hover {
        color: #000;
    }
    &:focus {
        box-shadow: none;
    }
}

.dynamicLinkBtn {
    border-radius: 4px !important;
    background: #fff !important;
    color: #6F4183;
    padding: 6px 17px;
    border: 1px solid #6F4183;
    &:hover {
        color: #6F4183;
    }
    &:focus {
        box-shadow: none;
    }
}

.continueBtn {
    border-radius: 4px !important;
    background: #43ba08 !important;
    color: #fff;
    &:hover {
        color: #fff;
        background: #194602 !important;
    }
    &:focus {
        box-shadow: none;
    }
}

.logoutBtn {
    border-radius: 4px !important;
    background: #ba2c08 !important;
    color: #fff;
    &:hover {
        color: #fff;
        background: #501304 !important;
    }
    &:focus {
        box-shadow: none;
    }
}

.cancelInviteBtn {
    border-radius: 4px !important;
    color: #000;
    padding: 6px 17px;
    &:hover {
        color: #000;
    }
    &:focus {
        box-shadow: none;
    }
}

.convertBtn {
    border-radius: 4px !important;
    background: #6F4183 !important;
    color: #fff;
    padding: 13px 17px;
    border: 1px solid #6F4183;
    &:hover {
        color: #6F4183;
        background: #fff !important;
    }
    &:focus {
        box-shadow: none;
    }
}

.convertBtnOutline {
    border-radius: 4px !important;
    background: #fff !important;
    color: #6F4183;
    padding: 13px 17px;
    border: 1px solid #6F4183;
    &:hover {
        color: #fff;
        background: #6F4183 !important;
    }
    &:focus {
        box-shadow: none;
    }
}

.float-right {
    float: right;
}

.cardShadow {
    box-shadow: 0px 2px 5px 0px rgba(7, 0, 31, 0.08) !important;
}

.actionViewBtn {
    border-radius: 5px;
    color: #6C6A78;
    background: rgba(71, 57, 141, 0.10);
    border: none;
    font-weight: 600;
    &:focus {
        box-shadow: none;
    }
}

.actionViewWalletBtn {
    border-radius: 5px;
    color: #000;
    background: #fff;
    border: 1px solid #6c6a782b;
    font-weight: 600;
    &:focus {
        box-shadow: none;
    }
}

.blockCard {
    border-radius: 5px;
    color: #df3535;
    background: #fff;
    border: 1px solid #df3535;
    font-weight: 600;
    &:focus {
        box-shadow: none;
    }
    &:hover {
        color: #df3535;
    }
}

.unblockCard {
    border-radius: 5px;
    color: #3fda24;
    background: #fff;
    border: 1px solid #3fda24;
    font-weight: 600;
    &:focus {
        box-shadow: none;
    }
    &:hover {
        color: #3fda24;
    }
}

.terminateCard {
    border-radius: 5px;
    color: #248bda;
    background: #fff;
    border: 1px solid #248bda;
    font-weight: 600;
    &:focus {
        box-shadow: none;
    }
    &:hover {
        color: #248bda;
    }
}

.paymentEditBtn {
    border-radius: 5px;
    color: #000;
    background: #F3F3F3;
    font-weight: 500;
    &:focus {
        box-shadow: none;
    }
}

.actionDeleteBtn {
    border-radius: 5px;
    color: #fff;
    background: rgba(184, 19, 19, 0.47);
    border: none;
    font-weight: 600;
    &:focus {
        box-shadow: none;
    }
    &:hover {
        color: #fff;
        background: rgba(184, 19, 19, 0.863);
    }
}

.actionEnableBtn {
    border-radius: 5px;
    color: #fff;
    background: rgba(80, 184, 19, 0.47);
    border: none;
    font-weight: 600;
    &:focus {
        box-shadow: none;
    }
    &:hover {
        color: #fff;
        background: rgba(63, 184, 19, 0.863);
    }
}

.actionDisableBtn {
    border-radius: 5px;
    color: #fff;
    background: rgba(184, 102, 19, 0.47);
    border: none;
    font-weight: 600;
    &:focus {
        box-shadow: none;
    }
    &:hover {
        color: #fff;
        background: rgba(184, 93, 19, 0.863);
    }
}

.invite-member-modal .modal-md {
    max-width: 427px;
}

.checkout-modal .modal-md {
    max-width: 800px;
}

.card-detail-modal .modal-md {
    max-width: 532px;
}

.statement-modal .modal-md {
    max-width: 850px;
}

.view-member-modal .modal-md {
    max-width: 432px;
}

.view-idle-modal .modal-md {
    max-width: 432px;
}

.view-2factor-modal .modal-md {
    max-width: 532px;
}

.withdraw-modal .modal-md {
    max-width: 720px;
}

.createlink-modal .modal-md {
    max-width: 400px;
}

.transaction-detail-modal .modal-md {
    max-width: 720px;
    top: -28px;
    left: 385px;
    transform: translate(-50%, 0);
    @media (max-width: 640px) {
        top: 0;
        left: 0;
        transform: translate(-50%, 0);
    }
}

.payout-detail-modal .modal-md {
    max-width: 720px;
}

.form-select:focus {
    box-shadow: none;
}

.mr-10 {
    margin-right: 10px;
}

.viewMemberUserNameBadge {
    background-color: #41B7E4;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    font-size: 45px;
    text-align: center;
    padding-top: 17px;
}

.clockIconIdle {
    font-size: 54px;
    padding: 17px;
    background-color: rgba(255, 153, 0, 0.3);
    border-radius: 50%;
    color: rgba(255, 153, 0, 0.929);
    ;
}

.deleteIconModal {
    font-size: 54px;
    padding: 17px;
    background-color: rgba(255, 38, 0, 0.3);
    border-radius: 50%;
    color: rgba(255, 13, 0, 0.929);
    ;
}

.successIconModal {
    font-size: 54px;
    padding: 17px;
    background-color: rgba(0, 255, 26, 0.3);
    border-radius: 50%;
    color: rgba(11, 84, 8, 0.929);
    ;
}

.settingsToggle {
    @media (max-width: 640px) {
        float: right;
    }
}

.factorSave {
    margin-right: 65px;
    @media (max-width: 640px) {
        margin-right: 0px;
    }
}

.authTextCopy {
    font-size: 10px;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.20);
    padding: 5px 2px;
    text-align: center;
    font-weight: 600;
}

.authTextCopyIcon {
    padding: 6px;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.20);
}

.upperBreadandBtn {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    float: right;
    .selectItem {
        width: 190px;
        padding: 13px 0px 13px 35px;
    }
    @media (max-width: 640px) {
        .selectItem {
            width: 120px;
        }
    }
}

.select-wrapperMine {
    position: relative;
    display: inline-block;
}

.select-wrapperMine .icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.searchAll {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    float: right;
    .searchTitle {
        width: 300px;
    }
    .searchSelectTitle {
        width: 170px;
        border-radius: 4px;
    }
    @media (max-width: 640px) {
        .searchTitle {
            width: 100%;
        }
        margin-top: 10px;
        float: left;
    }
}

.input-wrapperMine {
    position: relative;
    display: inline-block;
    .icon {
        position: absolute;
        right: 10px;
        top: 54%;
        transform: translateY(-50%);
    }
}

.font-size-8 {
    font-size: 8px;
}

.font-size-21 {
    font-size: 21px;
}

.withUserIcon {
    background-color: red;
    color: #fff;
    padding: 10px;
    border-radius: 50%;
    font-size: 22px;
}

.nairaWithdraw {
    font-size: 23px;
    background-color: #6F4183;
    color: #fff;
    padding: 2px;
    border-radius: 8px;
}

.nairaWithdraw2 {
    font-size: 23px;
    background-color: #828282;
    color: #fff;
    padding: 2px;
    border-radius: 8px;
}

.passFormCheck {
    padding: 0px 60px;
    @media (max-width: 640px) {
        padding: 0px 20px;
    }
}

.transDetailSide {
    padding: 0px 40px;
    @media (max-width: 640px) {
        padding: 0px 10px;
    }
}

.fw-100 {
    font-weight: 100;
}

.fw-400 {
    font-weight: 400;
}

.toRight {
    float: right;
}

.toRight2 {
    float: right;
    @media (max-width: 640px) {
        float: left;
    }
}

.transDetailSuc {
    background-color: #E6F9F2;
    color: #00BE7C;
    text-align: center;
    padding: 3px;
    border-radius: 9px;
    font-weight: 600;
}

.transDetailErr {
    background-color: #f9e8e6;
    color: #be2f00;
    text-align: center;
    padding: 3px;
    border-radius: 9px;
    font-weight: 600;
}

.transDetailPen {
    background-color: #f9f5e6;
    color: #bea800;
    text-align: center;
    padding: 3px;
    border-radius: 9px;
    font-weight: 600;
}

.logoAuthAdjust {
    padding: 30px 70px;
    @media (max-width: 640px) {
        padding: 10px 10px;
    }
}

.intWidth {
    width: 35%;
    @media (max-width: 640px) {
        width: 100%;
    }
}

.settingsTextWidth {
    width: 8%;
    @media (max-width: 640px) {
        width: 45%;
    }
}

.walletOtp {
    padding: 0px 140px;
    @media (max-width: 640px) {
        padding: 1.25rem;
    }
}

.createPad {
    padding: 40px 60px;
    @media (max-width: 640px) {
        padding: 1.25rem;
    }
}

.accountDetail {
    padding: 48px 1.25rem;
    @media (max-width: 640px) {
        padding: 1.25rem;
    }
}

.bottomAlign {
    position: absolute;
    bottom: 0;
    width: 75%;
    color: #fff;
    padding: 10px;
}

.liState {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.56px;
}

.fs-8 {
    font-size: 8px;
}

.fs-10 {
    font-size: 10px;
}

.fs-11 {
    font-size: 11px;
}

.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px;
}

.fw-600 {
    font-weight: 600;
}

.fw-500 {
    font-weight: 500;
}

.circleDanger {
    width: 10px;
    height: 10px;
    background-color: #DE3730;
    border-radius: 50%;
    display: inline-block;
}

.circleSuccess {
    width: 10px;
    height: 10px;
    background-color: #066c37;
    border-radius: 50%;
    display: inline-block;
}

.topUp {
    background-color: #3E1152;
    color: #fff;
    width: 100%;
    padding: 14px;
    border-radius: 10px;
}

.topUpOutline {
    background-color: #fff;
    color: #3E1152;
    width: 100%;
    padding: 14px;
    border-radius: 10px;
    border: 1px solid #3E1152;
}

.moneyBack {
    border-radius: 8px;
    border: 0.5px solid #E1E1E1;
    background: #FFF;
}

.moneyForm {
    border: 0px solid #F4FBFA !important;
    font-size: 19px !important;
    color: #7A7A7A !important;
    box-shadow: none !important;
    border-radius: 0px !important;
    padding: 0px 3px !important;
}

.nairaChange {
    color: #7A7A7A;
    font-size: 19px;
}

.formCheckout {
    padding: 9px;
    border-radius: 10px;
    border-bottom: 1px solid #9563FF;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}